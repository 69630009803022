<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Teacher Rates</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="600">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Teacher Rate Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" md="12">
                  <v-row dense>
                    <!--Start Date-->
                    <v-col cols="12" sm="6">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="d_dateStart"
                            label="Start Date"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            @blur="date1 = $_parseDate(d_dateStart)"
                            required
                            :rules="rules.d_dateStart"
                          />
                        </template>
                        <v-date-picker
                          v-model="date1"
                          @input="menu = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Experience-->
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="d_experience"
                        label="Years of Experience"
                        placeholder=" "
                        persistent-placeholder
                        required
                        :rules="d_experience === 0 ? [] : rules.d_experience"
                      />
                    </v-col>
                    <!--Rate-->
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="d_rate"
                        label="Rate"
                        placeholder=" "
                        persistent-placeholder
                        required
                        :rules="rules.d_rate"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Status-->
                    <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        :items="valueListsStore.activeStatus"
                        label="Status"
                        placeholder=" "
                        persistent-placeholder
                        item-text="value"
                        item-value="id"
                        v-model="f_status"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "teacherRatesStore/getEntryField",
  mutationType: "teacherRatesStore/updateEntryField"
});

export default {
  name: "TeacherRateEntry",
  mixins: [mixin],
  components: {
    TextDisplay
  },
  computed: {
    ...mapState({
      teacherRatesStore: state => state.teacherRatesStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",

      "d_experience",
      "d_rate",
      "d_dateStart",
      "f_status",
      "f_show",
      "date1"
    ])
  },
  data() {
    return {
      menu: false,
      rules: {
        d_dateStart: [
          v => !!v || "Date is required",
          v => (v !== null && v.length >= 10) || "Date must be in MM/DD/YYYY format"
        ],
        d_experience: [v => !!v || "Experience is required"],
        d_rate: [v => !!v || "Rate is required"]
      }
    };
  },
  watch: {
    date1() {
      this.d_dateStart = this.$_formatDate(this.date1);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("teacherRatesStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();

      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["teacherRatesStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("teacherRatesStore/create", entry);
        } else {
          /* Edit */
          await this.$store.dispatch("teacherRatesStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
