<template>
  <div>
    <!--Title Bar-->
    <title-bar :title-value="'Teacher Rates'" />

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span></v-btn
          >
        </template>

        <v-card width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <!--Start Date-->
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateStart"
                        label="Start Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        @blur="date1 = $_parseDate(d_dateStart)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <!--Experience-->
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_experience"
                    label="Years of Experience"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Entry Dialog-->
      <teacher-rate-entry />
      <!--Duplicate Button-->
      <v-tooltip
        top
        :color="teacherRatesStore.filters.selectedID.length > 0 ? 'primary' : 'grey lighten-1'"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click="teacherRatesStore.filters.selectedID.length > 0 ? openDuplicateDialog() : ''"
          >
            <v-icon
              small
              :color="
                teacherRatesStore.filters.selectedID.length > 0 ? 'primary' : 'grey lighten-1'
              "
            >
              fal fa-clone
            </v-icon>
          </v-btn>
        </template>
        <span>Duplicate Selection</span>
      </v-tooltip>
    </v-toolbar>

    <!--Duplicate Dialog-->
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Duplicate Rate(s)?</span>
          <v-spacer />
          <v-btn outlined rounded text @click.native="closeDuplicateDialog()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12" md="6">
                Select Rate Start Date.
                <!--Start Date-->
                <v-menu
                  :close-on-content-click="false"
                  v-model="menu2"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="massStartDate"
                      placeholder=" "
                      persistent-placeholder
                      prepend-icon="fal fa-calendar-alt"
                      required
                      readonly
                      @blur="date2 = $_parseDate(massStartDate)"
                    />
                  </template>
                  <v-date-picker
                    v-model="date2"
                    @input="menu2 = false"
                    color="primary"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click.native="duplicateRate()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Pagination-->
    <pagination
      :show="teacherRatesStore.teacherRates.data && teacherRatesStore.teacherRates.data.length > 0"
      :showTotal="true"
      :currentPage="teacherRatesStore.teacherRates.current_page"
      :lastPage="teacherRatesStore.teacherRates.last_page"
      :total="teacherRatesStore.teacherRates.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--Table-->
      <v-simple-table
        v-if="teacherRatesStore.teacherRates.data && teacherRatesStore.teacherRates.data.length > 0"
        :fixed-header="true"
        class="my-2"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left grey--text text--darken-1">Start Date</th>
              <th class="text-left grey--text text--darken-1">Experience</th>
              <th class="text-left grey--text text--darken-1">Rate</th>
              <th class="text-left grey--text text--darken-1">Status</th>
              <th class="text-left grey--text text--darken-1">
                <v-btn rounded @click="selectDeselectAll()" color="primary" class="py-0"
                  ><v-icon small left>fal fa-check-double</v-icon>All</v-btn
                >
              </th>
            </tr>
          </thead>
          <tbody
              class="clickable"
              v-for="(item, index) in teacherRatesStore.teacherRates.data"
              :key="item.id"
              @click="openEntry(item.id)"
          >
            <tr v-bind:style="index > 0 ? 'border-top: solid thin #dae1e7' : ''">
              <td class="text-left light-blue--text text--darken-4">
                {{ $_formatDate(item.d_dateStart) }}
              </td>
              <td class="text-left light-blue--text text--darken-4">
                {{ item.d_experience }}
              </td>
              <td class="text-left light-blue--text text--darken-4">
                {{ $_formatMoney(item.d_rate) }}
              </td>
              <td class="text-left light-blue--text text--darken-4">
                {{ $_activeStatus(item.f_status) }}
              </td>
              <td class="text-left light-blue--text text--darken-4">
                <v-checkbox
                  dense
                  :key="index"
                  v-model="selectedID"
                  :value="item.id"
                  color="primary"
                  class="pr-2"
                  @click.stop=""
                  multiple
                >
                </v-checkbox>
              </td>
            </tr>
            <tr>
              <td colspan="5" style="border-top: solid white; vertical-align: text-top; height:5px">
                <span class="text-caption grey--text"
                  >Created: {{ $_formatTimestamp(item.created_at) }} &#8226;
                  Modified: {{ $_formatTimestamp(item.updated_at) }}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Teacher Rates Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TeacherRateEntry from "@/components/TeacherRateEntry";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "teacherRatesStore/getFilterField",
  mutationType: "teacherRatesStore/updateFilterField"
});

export default {
  name: "Rates",
  mixins: [mixin],
  components: {
    TitleBar,
    TeacherRateEntry,
    Pagination,
    TextDisplay
  },
  computed: {
    ...mapState({
      teacherRatesStore: state => state.teacherRatesStore,
      valueListsStore: state => state.valueListsStore,
      countiesStore: state => state.countiesStore,
      programsStore: state => state.programsStore,
      ageCategoriesStore: state => state.ageCategoriesStore,
      ratingsStore: state => state.ratingsStore,
      progressStore: state => state.progressStore
    }),
    ...mapFields(["page", "d_dateStart", "d_experience", "date1", "selectedID"])
  },
  data() {
    return {
      menu: false,
      menu2: false,
      massStartDate: '',
      date2: '',
      filterMenu: false,
      showDialog: false
    };
  },
  watch: {
    date1() {
      this.d_dateStart = this.$_formatDate(this.date1);
    },
    date2() {
      this.massStartDate = this.$_formatDate(this.date2);
    }
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);

      /*clear checkbox selections*/
      this.$store.dispatch("teacherRatesStore/clearSelected");

      /* Grab filters */
      const filters = this.$store.getters["teacherRatesStore/getFilters"];
      /* Records based on filters */
      await this.$store.dispatch("teacherRatesStore/fetch", filters);

      /* Set default mass assignment start date*/
      this.date2 = this.$_dateOfNextMonth(1);

      this.$store.dispatch("progressStore/set", false);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    clearFilterColumns() {
      this.d_dateStart = "";
      this.d_experience = "";
      this.date1 = "";
      this.page = 1;
    },

    showFiltered() {
      if (this.d_dateStart || this._experience) {
        return true;
      }
    },

    async openEntry(id) {
      await this.$store.dispatch("teacherRatesStore/read", id);

      await this.$store.dispatch("teacherRatesStore/entry", id);
    },

    selectDeselectAll() {
      this.$store.dispatch("teacherRatesStore/selectDeselectAll");
    },

    openDuplicateDialog() {
      this.showDialog = true;
    },

    closeDuplicateDialog() {
      this.showDialog = false;
    },

    async duplicateRate() {
      //retrieve all selected id's from the selectedID state
      const selected = this.$store.getters["teacherRatesStore/getSelected"];
      const data = {
        items: selected,
        startDate: this.date2
      };
      await this.$store.dispatch("teacherRatesStore/duplicateSelected", data);
      this.initialize();
      this.closeDuplicateDialog();
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
